import React from "react";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Vilanguage from "../../languages/vi";

const LanguageDisplay = Vilanguage;

export default class FooterView extends React.Component {
  render() {
    return (
        <>
          <div className="footer-container">
            <div className="container">
              <h5 className="text-center text-uppercase mb-3">
                {LanguageDisplay.footer_titleName}
              </h5>
              <div className="mb-1">
                <FontAwesomeIcon
                    icon={faMapMarkedAlt}
                    color="white"
                    className="mr-2"
                />{" "}
                Địa chỉ: <span>{LanguageDisplay.footer_address}</span>
              </div>
              <div className="mb-1">
                <FontAwesomeIcon icon={faPhoneAlt} color="white" className="mr-2" />{" "}
                <span>
              Hotline: {LanguageDisplay.footer_hotline}
                  {Vilanguage.footer_fax && (
                      <span> / Fax:{Vilanguage.footer_fax}</span>
                  )}
            </span>
              </div>
              <div>
                <FontAwesomeIcon icon={faEnvelope} color="white" className="mr-2" />{" "}
                <span>E-mail: {LanguageDisplay.footer_email}</span>
              </div>
            </div>
            <div className="footer-link">
              <a href="#">TRANG CHỦ</a>
                <span>|</span>
                <a href="#">CSDL QUY HOẠCH - ĐẤT ĐAI - XÂY DỰNG</a>
              <span>|</span>
                <a href="#">CSDL AN SINH XÃ HỘI</a>
              <span>|</span>
                <a href="#">TIỆN ÍCH</a>
              <span>|</span>
                <a href="#">THÔNG TIN</a>
              <span>|</span>
                <a href="#">PHẢN ÁNH HIỆN TRƯỜNG</a>
            </div>
          </div>
          <div className="footer-copyright-box">
            <div className="container footer-copyright">
              ©2000 - 2021 UBND quận Hải Châu. Phát triển bởi: <a href={Vilanguage.footer_ctech_url}>Công ty cổ phần công nghệ cao CTECH</a>
            </div>
          </div>
        </>

    );
  }
}
