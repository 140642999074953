export default {
  planningMapView: {
    baseMap: "Bản đồ nền",
    toolsMap: "Công cụ bản đồ",
    googleMap: "Bản đồ Google",
    satelliteMap: "Bản đồ vệ tinh",
    notUseBaseMap: "Không dùng bản đồ nền",
    mapToolsPanel: {
      viewObjectInfomation: "Xem thông tin đối tượng",
      toggleFullScreen: "Mở rộng/Thu nhỏ",
      zoomIn: "Phóng to bản đồ",
      zoomOut: "Thu nhỏ bản đồ",
      filterInfomation: "Lọc thông tin",
      mapLegend: "Chú giải bản đồ",
      selectAnObject: "Chọn một đối tượng",
      selectMultiObject: "Chọn nhiều đối tượng",
      measuareArea: "Đo diện tích",
      measuareDistance: "Đo khoảng cách",
      moveViewport: "Di chuyển khung nhìn",
      printTheMap: "In bản đồ",
      myPosition: "Xác định vị trí của bạn",
      exportMap: "Xuất bản đồ",
      compare: "So sánh",
      locationMarker: "Đánh dấu vị trí",
    },
    filterInfomationPopup: {
      headTitle: "Tìm kiếm đối tượng",
      labelSelectSearch: "Chọn đối tượng tìm kiếm",
      searchInLine: "Tìm kiếm trên đường",
      searchInArea: "Tìm kiếm trong vùng",
      searchInCircle: "TÌm kiếm trong đường tròn",
      searchInSquare: "Tìm kiếm trong hình chữ nhật",
      searchWithAttribute: "Theo thuộc tính",
      search: "Tìm kiếm",
      delete: "Xóa",
      close: "Đóng",
    },
  },
  planningMapView_guideToUse: "Hướng dẫn sử dụng",
  planningMapView_guideToUse_category: "Danh mục hướng dẫn",
  planningMapView_map: "Bản đồ",
  planningMapView_infomation: "Thông tin",
  planningMapView_relatedPlanning: "Cơ sở dữ liệu khác",
  planningMapView_result: "Lọc kết quả",
  header_homePage: "Trang chủ",
  header_pleaseConsultTheCommunity: "Xin ý kiến cộng đồng",
  header_lookUpPlanning: "Tra cứu quy hoạch",
  header_news: "Tin tức",
  header_planningAnnouncement: "Công bố quy hoạch",
  header_recordsManager: "Quản lý hồ sơ",
  header_Contact: "Liên hệ",
  header_generalPlanning: "Quy hoạch chung",
  header_syntheticPlanning: "Tổng hợp quy hoạch",
  header_subdivisionPlanning: "Quy hoạch phân khu",
  header_detailedPlanning: "Quy hoạch chi tiết",
  header_specializedPlanning: "Quy hoạch chuyên ngành",
  header_administration: "Quản trị",
  header_logout: "Đăng xuất",
  header_logIn: "Đăng Nhập",
  header_account: "Tài khoản",
  header_title: "CSDL ĐÔ THỊ - ĐÔ THỊ THÔNG MINH - QUẬN HẢI CHÂU",
  footer_titleName: "HỆ THỐNG CSDL ĐÔ THỊ QUẬN HẢI CHÂU - THÀNH PHỐ ĐÀ NẴNG",
  footer_ctech_url: "https://c2tech.vn",
    footer_email: "quanhaichau@danang.gov.vn",
    footer_email_to: "quanhaichau@danang.gov.vn",
    footer_address: "270 Trần Phú -Q.Hải Châu- TP.Đà Nẵng",
    footer_hotline: "(84236) 3827970",
    footer_hotline_tel: "842363827970",
    footer_fax: "(84236) 3872774",
    footer_website: "http://haichau.danang.gov.vn/",
    footer_website_to: "http://haichau.danang.gov.vn/", 
    contact_map_url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.821747808965!2d108.21689941466343!3d16.074737143560565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3142184829908b3b%3A0xecb4b3e428a2674e!2zVUJORCBxdeG6rW4gSOG6o2kgQ2jDonU!5e0!3m2!1sen!2s!4v1637816182229!5m2!1sen!2s',
};
