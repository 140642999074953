import React from "react";
export default function BannerView(props) {
    return (
        <header className="header">
            <div className="container header-banner" id="banner">
                <div className="row">
                    <div className="col-md-12">
                        <a href="/">
                            <img
                                className="logo"
                                src={require("../../assets/image/logo.png")}
                                alt="Logo"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
}
