/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { geolocated } from "react-geolocated";
import { AsyncPaginate } from "react-select-async-paginate";

import * as homePageStore from "../../redux/store/home-page/home-page.store";
import * as appActions from "../../core/app.store";
import NewsHome from './news/news.view';

import UrlCollect from "../../common/url-collect";
import history from "../../common/history";
import {
  APIUrlDefault,
  changeAlias,
  getUserInfo,
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.scss";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import * as mapHelper from "../../common/map";
import { transform } from "ol/proj";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";

function HomeDesktop(props) {
  const { showLoading } = props;

  const isLogin = getUserInfo() ? true : false;

  const settingSlider = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [slideShow, setSlideShow] = useState([]);
  const [projectList, setProjectList] = useState();
  const [commentInput, setCommentInput] = useState("");
  const [address, setAddress] = useState("");
  const [currentPosition, setCurrentPosition] = useState({
    latitude: null,
    longitude: null,
    altitude: null,
    accuracy: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  });
  const [openSearchPlace, setOpenSearchPlace] = useState(true);

  useEffect(() => {
    onGetData();
    props.GetAllPlanningBoundariesGeomText();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetSlideShow(),
      onGetAllPlanningByTypeId(),
      onGetCurrentPosition(),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetSlideShow = () => {
    return new Promise((resolve, reject) => {
      props
        .getSlideShow()
        .then((res) => {
          setSlideShow(res && res.content && res.content.length > 0 ? res.content : []);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const onGetAllPlanningByTypeId = (id) => {
    return new Promise((resolve, reject) => {
      props
        .getAllPlanningByTypeId(id)
        .then((res) => {
          setProjectList(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                    key: item.planningTypeId,
                    labelTemp: changeAlias(item.name),
                  };
                })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const onGetCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentPosition(position.coords);
          resolve(position);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const handleSelectAddress = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let isInside = false;
        props.listPolygon &&
          props.listPolygon.map((itemPolygon,index) => {
            if (
              mapHelper.isPointInPolygon(latLng.lat, latLng.lng, itemPolygon)
            ) {
              isInside = true;
              return;
            }
          });
        if (isInside) {
          history.push(
            `${UrlCollect.SyntheticPlanning}?lat=${latLng.lat}&lng=${latLng.lng}&searchType=place`
          );
        } else {
          ShowNotification(
            viVN.Errors.CoordinateOutSidePolygon,
            NotificationMessageType.Warning,
            NotificationPosition.Center
          );
        }
      })
      .catch();
  };

  const onChangeProject = (data) => {
    data && history.push(`${UrlCollect.PlanningMap}/${data.value}`);
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const showAlertDoing = () => {
    ShowNotification(
        "Đang cập nhật dữ liệu",
        NotificationMessageType.Warning
    );
  }

  const loadOptions = async (search, prevOptions) => {
    await sleep(500);
    let arr = [];
    let filteredOptions;
    let filteredOptions1;
    if (!search) {
      filteredOptions = projectList;
    } else {
      const searchLower = changeAlias(search);

      filteredOptions = projectList.filter(({ labelTemp }) =>
        labelTemp.toLowerCase().includes(searchLower)
      );
      if (!(filteredOptions.length > 0)) {
        let searchArray = searchLower.split(" ");
        filteredOptions1 = projectList.filter(({ labelTemp }) => {
          arr = searchArray.filter((item) => labelTemp.includes(item));
          if (arr.length === searchArray.length) return true;
        });
        const hasMore = filteredOptions.length > prevOptions.length + 10;
        const slicedOptions = filteredOptions1.slice(
          prevOptions.length,
          prevOptions.length + 10
        );

        return {
          options: slicedOptions,
          hasMore,
        };
      }
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  const handleOpenCoordinate = () => {
    setOpenSearchPlace(false);
  };

  const handleOpenPlace = () => {
    setOpenSearchPlace(true);
  };

  const searchCoordinate = (event) => {
    event.preventDefault();
    let typeSearch = openSearchPlace ? "place" : "codinate";
    let value = commentInput.value.trim();
    let coordinateList = value.split(",");
    if (coordinateList && coordinateList.length === 2) {
      let x = parseFloat(coordinateList[0]);
      let y = parseFloat(coordinateList[1]);
      proj4.defs(
        "EPSG:3405",
        "+proj=tmerc +lat_0=0 +lon_0=107.75 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,-0.00928836,0.01975479,-0.00427372,0.252906278 +units=m +no_defs"
      );
      register(proj4);
      var center = transform([x, y], "EPSG:3405", "EPSG:4326");
      let isInside = false;
      props.listPolygon &&
        props.listPolygon.map((itemPolygon) => {
          if (mapHelper.isPointInPolygon(center[1], center[0], itemPolygon)) {
            isInside = true;
            return;
          }
        });
      if (isInside) {
        history.push(
          `${UrlCollect.SyntheticPlanning}?lng=${x}&lat=${y}&searchType=${typeSearch}`
        );
      } else {
        ShowNotification(
          viVN.Errors.CoordinateOutSidePolygon,
          NotificationMessageType.Warning,
          NotificationPosition.Center
        );
      }
    }
  };

  const classnames = (...args) => {
    const classes = [];
    args.forEach((arg) => {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (typeof arg === "object" && arg !== null) {
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            classes.push(key);
          }
        });
      } else {
        throw new Error(
          "`classnames` only accepts string or object as arguments"
        );
      }
    });

    return classes.join(" ");
  };

  const handleClickCurrentLocation = () => {
    let currentPositionTemp = currentPosition;

    if (!currentPositionTemp) {
      navigator.geolocation.getCurrentPosition((position) => {
        currentPositionTemp = currentPosition;
      });
    }

    if (currentPositionTemp.latitude && currentPositionTemp.longitude) {
      let isInside = false;
      props.listPolygon &&
        props.listPolygon.map((itemPolygon) => {
          if (
            mapHelper.isPointInPolygon(
              currentPositionTemp.latitude,
              currentPositionTemp.longitude,
              itemPolygon
            )
          ) {
            isInside = true;
            return;
          }
        });
      if (isInside) {
        history.push(
          `${UrlCollect.SyntheticPlanning}?lat=${currentPositionTemp.latitude}&lng=${currentPositionTemp.longitude}&searchType=place`
        );
      } else {
        ShowNotification(
          viVN.Errors.CoordinateOutSidePolygon,
          NotificationMessageType.Warning,
          NotificationPosition.Center
        );
      }
    } else {
      ShowNotification(
        viVN.Errors.OpenCoordinateCurrent,
        NotificationMessageType.Error,
        NotificationPosition.TopRight
      );
    }
  };

  const handlePlacesError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  return (
    <div className="home-page">
      <div className="home-search">
        <div className="container">
          <div className="row align-items-center no-gutters">
            <div className="col-12 col-md-6 col-lg-6 mb-3 mb-md-0 mb-lg-0 search-input-planning">
              <img
                src={require("../../assets/icon/search-circle.svg")}
                alt="search-circle"
                className="float-left mr-2 mt-1"
              ></img>
              <AsyncPaginate
                className="react-select-container mr-2"
                classNamePrefix="react-select"
                cacheOptions
                defaultMenuIsOpen={true}
                defaultOptions
                loadOptions={loadOptions}
                onChange={onChangeProject}
                placeholder="Nhập tên Quy hoạch - Dự án"
                noOptionsMessage={() => "Không có dữ liệu"}
              />
            </div>

            <div className="col-12 col-md-6 col-lg-6 mb-3 mb-md-0 mb-lg-0">
              <div className="d-flex align-items-center">
                <img
                  src={require("../../assets/icon/planning-name.svg")}
                  alt="coordinate"
                  className={
                    "mr-2 cursor-pointer " +
                    (openSearchPlace ? "opacity-4" : "")
                  }
                  onClick={handleOpenCoordinate}
                ></img>

                <img
                  src={require("../../assets/icon/place.svg")}
                  alt="place"
                  className={
                    "mr-2 cursor-pointer " +
                    (!openSearchPlace ? "opacity-4" : "")
                  }
                  onClick={handleOpenPlace}
                ></img>

                <form onSubmit={searchCoordinate} className="w-100">
                  {!openSearchPlace ? (
                    <input
                      type="text"
                      ref={(input) => {
                        setCommentInput(input);
                      }}
                      name="address"
                      placeholder="Nhập toạ độ VN2000 (Toạ độ X, Toạ độ Y)"
                      className="input-search"
                    />
                  ) : (
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelectAddress}
                      onError={handlePlacesError}
                      clearItemsOnError={true}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="map__search-bar-container">
                          <div className="map__search-input-container">
                            <input
                              {...getInputProps({
                                placeholder: "Nhập địa điểm tra cứu",
                                className: "input-search",
                              })}
                            />
                          </div>
                          {suggestions.length > 0 && (
                            <div className="map__autocomplete-container">
                              {suggestions.map((suggestion, index) => {
                                const className = classnames(
                                  "map__suggestion-item",
                                  {
                                    "map__suggestion-item--active":
                                      suggestion.active,
                                  }
                                );
                                return (
                                  <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                    })}
                                  >
                                    <img
                                      src={require("../../assets/icon/location-pointer.svg")}
                                      alt="location-pointer"
                                      className="mr-2 map__suggestion-item--location-pointer"
                                    />
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  )}
                </form>

                <img
                  src={require("../../assets/icon/locate.svg")}
                  alt="locate"
                  className="cursor-pointer ml-2"
                  onClick={handleClickCurrentLocation}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      {slideShow && slideShow.length > 0 && (
        <div id="slider-home-page" className="slider-home-page">
          <Slider {...settingSlider}>
            {slideShow.map(
              (item, index) =>
                item.status && (
                  <div className="home-map" key={index}>
                    <a href={item && item.link ? item.link : "#"}>
                      <img src={APIUrlDefault + item.avatar} alt="Map" />
                    </a>
                    <div className="map-content p-4">
                      <h5 className="title">
                        {/* <a
                          className="text-white"
                          href={item && item.link ? item.link : ""}
                        > */}
                        {item.title}
                        {/* </a> */}
                      </h5>
                      <hr />
                      <p className="content">{item.introduce}</p>
                    </div>
                  </div>
                )
            )}
          </Slider>
        </div>
      )}

      <div className="home-content mt-1">
        <h2 className="title text-uppercase">
          CSDL ĐÔ THỊ - ĐÔ THỊ THÔNG MINH - QUẬN HẢI CHÂU THÀNH PHỐ ĐÀ NẴNG
        </h2>
        <hr />

        <div className="container">
          <div className="row content justify-content-between">
            <a onClick={() =>showAlertDoing()} href="#">
              <div className="home-entry-box home-entry-1">
                  <div className="entry-title">CSDL DÂN CƯ</div>
              </div>
            </a>
            <a href="/ban-do-quy-hoach/4">
              <div className="home-entry-box home-entry-2">
                <div className="entry-title">CSDL ĐẤT ĐAI</div>
              </div>
            </a>
            <a href="/ban-do-quy-hoach/1">
              <div className="home-entry-box home-entry-3">
                <div className="entry-title">CSDL QUY HOẠCH</div>
              </div>
            </a>
            <a href="/ban-do-quy-hoach/3">
              <div className="home-entry-box home-entry-4">
                <div className="entry-title">CSDL GIAO THÔNG</div>
              </div>
            </a>
            <a href="/ban-do-quy-hoach/5">
              <div className="home-entry-box home-entry-5">
                <div className="entry-title">DỰ ÁN ĐẦU TƯ</div>
              </div>
            </a>
            <div className="cb"></div>
          </div>
        </div>
      </div>
      <NewsHome />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  listPolygon: state.mapData.listPolygon,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getSlideShow: homePageStore.GetSlideShow,
      getAllPlanningByTypeId: homePageStore.GetAllPlanningByTypeId,
      GetAllPlanningBoundariesGeomText:
        MapDataStore.GetAllPlanningBoundariesGeomText,
    },
    dispatch
  );

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(HomeDesktop));
