const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://haichau-api.cgis.asia/",
        domainUserSide: "http://localhost:3000",
        domainAdminSide: "https://admin.haichau.cgis.asia",
        domainName: "",
        doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
        doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
  },
  production: {
      api: "https://haichau-api.cgis.asia/",
      domainUserSide: "https://haichau.cgis.asia",
      domainAdminSide: "https://admin.haichau.cgis.asia",
      domainName: "haichau.cgis.asia",
      doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
      doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/wms",
  },
};

module.exports = apiEnvironment[env];
